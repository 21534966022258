"use strict";



document.getElementById('navigation').addEventListener('click', () => {
    const b = document.body.querySelector('.navigation_mobile');
    b.classList.toggle('translate');

});


document.getElementById('arrow_link_2').addEventListener('click', () => {
    var b = document.body.querySelector('#menu_link_2');
    var c = document.body.querySelector('#arrow');

    b.classList.toggle('link_animation');  
    c.classList.toggle('translate_arrow');  
})

document.getElementById('arrow_link_3').addEventListener('click', () => {
    var b = document.body.querySelector('#menu_link_3');
    var c = document.body.querySelector('#arrow2');

    b.classList.toggle('link_animation');  
    c.classList.toggle('translate_arrow');  
})